import React from 'react'
import styled from "styled-components"
import {education} from "../../data/constants"
import { VerticalTimeline }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import EducationCard from "../cards/EducationCard"
import EarthCanvas from "../canvas/Earth"

const Education = () => {

    const Container = styled.div`
    padding: 40px 0;
    display: flex;
    flex-direction:column;
    justify-content:center;
    position:relative;
    z-index: 1;
    align-items:center;
`;

    const Wrapper = styled.div`
    position:relative;
    display: flex;
    justify-content:space-between;
    align-items:center;
    flex-direction:column;
    width:100%;
    max-width:1100px;
    gap:12px;
    @media (max-width: 960px) {
        flex-direction:column;
    }
`;
const Title = styled.div`
    font-size:52px;
    text-align: center;
    font-weight: 600;
    margin-top:20px;
    color: ${({theme}) => theme.text_primary};
    @media (max-width: 960px) {
        margin-top: 12px;
        font-size: 32px;
    }
`;
const Desc = styled.div`
    font-size:18px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 40px;
    color: ${({theme}) => theme.text_secondary};
`;

  return (
    <Container id="Education">
        <Wrapper>
            <Title>Education</Title>
                <Desc style={{marginBottom:"40px"}}>MY EDUCATION</Desc>
                <VerticalTimeline>
                    {education.map((education, index) => (
                        <EducationCard key={`education-${index}`}education={education} />
                    ))}
                </VerticalTimeline>
                <EarthCanvas />
        </Wrapper>
    </Container>
  )
}

export default Education