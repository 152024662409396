import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import styled from "styled-components";
import { init } from '@emailjs/browser';

// Initialize EmailJS with your public key
init(process.env.REACT_APP_EMAILJS_PUBLICKEY);


// Styled Components (unchanged)
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  align-items: center;
  margin-bottom: 80px;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1100px;
  gap: 12px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const Title = styled.div`
  font-size: 52px;
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
  color: ${({ theme }) => theme.text_primary};
  @media (max-width: 768px) {
    margin-top: 12px;
    font-size: 32px;
  }
`;

const Desc = styled.div`
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  color: ${({ theme }) => theme.text_secondary};
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const Form = styled.form`
  width: 95%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  background-color: rgba(17, 25, 40, 0.83);
  border: 1px solid rgba(255, 255, 255, 0.125);
  padding: 32px;
  border-radius: 12px;
  box-shadow: rgba(23, 92, 230, 0.1) 0px 4px 24px;
  margin-top: 15px;
  gap: 12px;
`;

const Input = styled.input`
  flex: 1;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.text_secondary + 50};
  outline: none;
  font-size: 18px;
  color: ${({ theme }) => theme.text_primary};
  border-radius: 12px;
  padding: 12px 16px;
  &:focus {
    border: 1px solid ${({ theme }) => theme.primary};
  }
`;

const InputMessage = styled.textarea`
  flex: 1;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.text_secondary + 50};
  outline: none;
  font-size: 18px;
  color: ${({ theme }) => theme.text_primary};
  border-radius: 12px;
  padding: 12px 16px;
  &:focus {
    border: 1px solid ${({ theme }) => theme.primary};
  }
`;

const ContactButton = styled.button`
  width: 100%;
  text-decoration: none;
  text-align: center;
  background: hsla(271, 100%, 50%, 1);
  padding: 13px 16px;
  margin-top: 2px;
  border-radius: 12px;
  border: none;
  color: ${({ theme }) => theme.text_primary};
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
`;

const ContactTitle = styled.div`
  font-size: 28px;
  margin-bottom: 6px;
  font-weight: 600;
  color: ${({ theme }) => theme.text_primary};
`;

const Contact = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const form = useRef();

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleValidation = (e) => {
    e.preventDefault();
    // Add your validation logic here (e.g., check if fields are empty)
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email) newErrors.email = 'Email is required';
    if (!formData.message) newErrors.message = 'Message is required';
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      sendEmail(e);
    }
  };

  const sendEmail = (e) => {
    setLoading(true);
    e.preventDefault();

    emailjs
      .sendForm(process.env.REACT_APP_EMAILJS_SERVICEID, "template_i6nbu68", form.current, {
        publicKey: process.env.REACT_APP_EMAILJS_PUBLICKEY,
      })
      .then(
        () => {
          setLoading(false);
          setIsFormSubmitted(true);
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
          setLoading(false);
        },
      );
  };

  return (
    <Container id="Contact">
      <Wrapper>
        <Title>Contact</Title>
        <Desc style={{ marginBottom: "40px" }}>
          Contact Me!
        </Desc>
        {!isFormSubmitted ? (
          <Form ref={form} onSubmit={handleValidation}>
            <ContactTitle>Email me 🚀</ContactTitle>
            <Input
              placeholder="Your Name"
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChangeInput}
            />
            {errors.name && <span style={{ color: 'red' }}>{errors.name}</span>}
            <Input
              placeholder="Your Email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChangeInput}
            />
            {errors.email && <span style={{ color: 'red' }}>{errors.email}</span>}
            <InputMessage
              placeholder="Message"
              name="message"
              rows={4}
              value={formData.message}
              onChange={handleChangeInput}
            />
            {errors.message && <span style={{ color: 'red' }}>{errors.message}</span>}
            <ContactButton type="submit" disabled={loading}>
              {loading ? 'Sending...' : 'Send'}
            </ContactButton>
          </Form>
        ) : (
          <div>
            <h3>Thank you for getting in touch!</h3>
          </div>
        )}
      </Wrapper>
    </Container>
  );
};

export default Contact;