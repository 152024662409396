import React from 'react'
import styled from 'styled-components'
import { Bio } from '../../data/constants';
import Typewriter from "typewriter-effect"
import HeroImg from "../../images/theMan.jpeg"
import HeroBgAnimation from "../HeroBgAnimation"
import {Tilt} from "react-tilt"
import {motion} from "framer-motion"
import {headContainerAnimation, headTextAnimation, headContentAnimation} from "../../utils/motion"
import Stars from "../canvas/Stars"

const HeroContainer = styled.div`
    display:flex;
    justify-content: center;
    position:relative;
    padding: 5% 30px;
    z-index: 1;
    height: 86vh;

    @media(max-width: 960px){
        padding: 66px 16px
    }
    @media(max-width: 640px){
        padding: 32px 16px
    }
`
const HeroInnerContainer = styled.div`
    position: relative;
    align-items:center;
    display:flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1100px;

    @media(max-width: 960px){
        flex-direction:column;
    }
`;

const HeroLeftContainer = styled.div`
    width: 100%;
    order: 1;
    
    @media(max-width: 960px){
        display:flex;
        order:2;
        gap:6px;
        margin-bottom: 30px;
        flex-direction: column;
        align-items:center;
        text-align:center;
    }

    @media(max-width: 640px){
        margin-bottom: 30px;
    }
`;
const HeroRightContainer = styled.div`
    width: 100%;
    order: 2;
    display:flex;
    justify-content: end;

    @media(max-width: 960px){
        display:flex;
        order:1;
        margin-bottom: 80px;
        flex-direction: column;
        align-items:center;
        justify-content:center;

    }
`;

const Title = styled.div`
    font-weight: 700;
    font-size: 50px;
    color: ${({theme}) => theme.text_primary};
    
    @media(max-width: 960px){
        text-align:center;
        font-size:40px;
        line-height: 48px;
        margin:bottom: 8px;
    }
`;

const TextLoop = styled.div`
    font-weight: 600;
    font-size: 32px;
    display: flex;
    gap: 12px;
    color: ${({theme}) => theme.text_primary};
    line-height: 68px;

    @media(max-width: 960px){
        text-align:center;
        justify-content:center;
        font-size:22px;
        line-height: 48px;
        margin:bottom:16px;
    }
`;

const Span = styled.div`
    cursor: pointer;
    color: ${({theme}) => theme.primary};
`;

const SubTitle = styled.div`
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 42px;
    color: ${({theme}) => theme.text_primary + 95};

    @media(max-width: 960px){
        text-align:center;
        font-size:16px;
        line-height: 32px;
        margin:bottom:16px;
    }

`;

const ResumeButton = styled.a`
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;
    text-decoration: none;

    width: 95%;
    max-width: 300px;
    text-align:center;
    padding: 16px 0;
    cursor:pointer;

    background: hsla(271, 100%, 50%, 1);
    background: linear-gradient(
    225deg,
    hsla(271, 100%, 50%, 1) 0%,
    hsla(294, 100%, 50%, 1) 100%
    );
    background: -moz-linear-gradient(
        225deg,
        hsla(271, 100%, 50%, 1) 0%,
        hsla(294, 100%, 50%, 1) 100%
    );
    background: -webkit-linear-gradient(
        225deg,
        hsla(271, 100%, 50%, 1) 0%,
        hsla(294, 100%, 50%, 1) 100%
    );

    box-shadow: 20px 20px 60px #1f2634, -20px -20px 60px #1f2634;
    border-radius: 50px;
    font-weight: 650;
    font-size: 20px; 

    &:hover {
        transform: scale(1.07);
        transition: all 0.4s ease-in-out;
        box-shadow:  20px 20px 60px #1F2634,
        filter: brightness(1);
        }    
    
    @media (max-width: 640px) {
        padding: 12px 0;
        font-size: 18px;
        justify-content: center;
        align-items:center;
        text-align:center;
        
    } 
    color: white;
`;

const Img = styled.img`
    border-radius:50%;
    width:400px;
    height:400px;;
    object-fit: cover;
    border: 2px solid ${({theme}) => theme.primary};

    @media (max-width: 640px) {
        width:280px;
        height:280px;
        object-fit: cover;
    } 
`;

const HeroBg = styled.div`
    position: absolute;
    display: flex;
    justify-content: end;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width:1350px;
    height: 100%;
    overflow: hidden;
    padding: 0 18px;
    top: 55%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);

    @media (max-width: 960px) {
        justify-content: center;
        padding: -100vh 0px;
        top: 47%;
    }
`;


const hero = () => {
  return (
    <div id="about">
        <HeroContainer>
            <HeroBg>
                <HeroBgAnimation />
                <Stars />
            </HeroBg>
            <motion.div {...headContainerAnimation}>
                <HeroInnerContainer>
                    <HeroLeftContainer>
                        <motion.div {...headTextAnimation}>
                            <Title>
                                Hi, I am <br/> {Bio.name}
                            </Title>
                            <TextLoop>
                                I am a 
                                <Span>
                                    <Typewriter
                                        options={{strings:Bio.roles, autoStart:true, loop:true}}
                                    />
                                </Span>
                            </TextLoop>
                            <motion.div {...headContentAnimation}>
                                <SubTitle>
                                    {Bio.description}
                                </SubTitle>
                            </motion.div>
                            <motion.div {...headContentAnimation}>
                                <ResumeButton href={Bio.resume} target="_Blank">Check my Resume!</ResumeButton>
                            </motion.div>
                        </motion.div>
                    </HeroLeftContainer>
                    <HeroRightContainer>
                    <motion.div {...headContentAnimation}>
                        <Tilt>
                            <Img src={HeroImg} alt="Dean Qasem"></Img>
                        </Tilt>
                    </motion.div>
                    </HeroRightContainer>
                </HeroInnerContainer>
            </motion.div>
        </HeroContainer>
    </div>
  )
}

export default hero