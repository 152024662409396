import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Preload, useGLTF } from "@react-three/drei";

const Earth = () => {
    const earth = useGLTF("./planet/scene.gltf")
  return (
    <primitive object={earth.scene} position-y={0} rotation-y={0} scale={3}/>
  )
}

const EarthCanvas = () =>{
    return  (
        <Canvas
            shadows
            frameloop="demand"
            dpr={[1,2]}
            gl={{
                fov: 45,
                near: 0.1,
                far: 200,
                position: [-4,3,6],
            }}
        >
            <Suspense fallback={null}>
                <OrbitControls
                    autoRotate
                    enableZoom={false}
                    maxPolarAngle={Math.PI /2}
                    minPolarAngle={Math.PI /2}
                />
            <Earth/>
            <Preload all />
            </Suspense>
        </Canvas>
    )
}
export default EarthCanvas;